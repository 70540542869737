import React from "react";
import styled from "styled-components";
import ExternalLink from "../../images/icons/external_link.svg";

const CardImageHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  min-height: 310px;
  @media (max-width: 576px) {
    min-height: 250px;
  }
  .card_imagelink {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 190px;
    margin-bottom: 1rem;
    width: 100%;
    h3 {
      text-align: center;
      font-size: 24px;
      color: #f6faff;
    }
    &:hover ${CardImageHover} {
      opacity: 0.85;
    }
    &:hover + .card_title {
      color: ${(props) => props.theme.brandBlue};
    }
  }
  .card_title {
    font-size: 20px;
    line-height: 24px;
    font-family: Telex;
    font-weight: 400;
    :hover {
      color: ${(props) => props.theme.brandBlue};
    }
  }
`;

const MediaCard = ({ title, company, image, link }) => {
  return (
    <Card>
      <a
        className="card_imagelink"
        href={link}
        rel="noreferrer"
        target="_blank"
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: `cover`,
        }}
      >
        <h3>{company}</h3>
        <CardImageHover>
          <img src={ExternalLink} alt={company}></img>
        </CardImageHover>
      </a>

      <a href={link} rel="noreferrer" target="_blank" className="card_title">
        {title}
      </a>
    </Card>
  );
};

export default MediaCard;
