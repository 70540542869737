import React, { useState } from "react";
import styled from "styled-components";
import MediaCard from "./MediaCard";
import { mediaData } from "../../data/mediaData";
import { navigate } from "gatsby";

// Assets
import NextIcon from "../../images/icons/next.svg";
import LastIcon from "../../images/icons/last.svg";
import DownloadIcon from "../../images/icons/download.svg";
// import MediaKitZip from "../../docs/KeyFi-MediaKit.zip";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  margin: 0 auto;

  h2 {
    margin: 4rem 0 2rem;
    text-align: center;
    font-size: 32px;
  }
  .media_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 272px);
    gap: 1rem;
    row-gap: 3rem;
    justify-content: center;
  }
  .media_pagination {
    margin: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .media_pagination_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border: 1px solid #d4e7ff;
      border-radius: 3px;
      color: ${(props) => props.theme.brandBlue};
      :hover {
        border-color: ${(props) => props.theme.brandBlue};
        background: ${(props) => props.theme.lightBlue2};
      }
    }
  }
  .media_kit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
    h2 {
      margin: 2rem 0 1.5rem;
    }
    p {
      margin: 0;
      text-align: center;
      a {
        color: ${(props) => props.theme.brandBlue};
      }
    }
    .media_kit_buttoncontainer {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      .media_kit_button {
        padding: 0.75rem 1rem;
        font-size: 20px;
        font-weight: 600;
        border-radius: 6px;
        text-transform: uppercase;
      }
      .media_kit_download {
        display: flex;
        align-items: center;
        color: white;
        margin-right: 1.25rem;
        background: linear-gradient(256.02deg, #0ca7eb 2.19%, #005ad3 188.15%);
        img {
          margin-right: 10px;
        }
        span {
          font-size: 20px;
          font-weight: 600;
          text-transform: uppercase;
        }
        :hover {
          background: linear-gradient(
            256.02deg,
            #005ad3 2.19%,
            #0ca7eb 188.15%
          );
        }
      }
      .media_kit_contact {
        color: ${(props) => props.theme.brandBlue};
        background: 0;
        border: 1px solid ${(props) => props.theme.brandBlue};
        :hover {
          background: ${(props) => props.theme.lightBlue2};
        }
      }
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        .media_kit_download {
          margin: 0 0 1rem;
        }
      }
    }
  }
`;

const MediaContainer = () => {
  const [page, setPage] = useState(0);
  const CARDS_PER_PAGE = 12;
  return (
    <Wrapper>
      <h2>KeyFi in the media</h2>
      <div className="media_grid">
        {mediaData.map(
          (item, index) =>
            index + 1 > page * CARDS_PER_PAGE &&
            index + 1 <= (page + 1) * CARDS_PER_PAGE && (
              <MediaCard
                title={item.title}
                company={item.mediaCompany}
                image={item.img}
                link={item.link}
              />
            )
        )}
      </div>
      <div className="media_pagination">
        {page !== 0 && (
          <>
            <div
              className="media_pagination_item media_pagination_first"
              onClick={() => setPage(0)}
            >
              <img
                src={LastIcon}
                style={{ transform: "rotate(180deg)" }}
                alt="Last page"
              ></img>
            </div>
            <div
              className="media_pagination_item media_pagination_previous"
              onClick={() => setPage(page - 1)}
            >
              <img
                src={NextIcon}
                style={{ transform: "rotate(180deg)" }}
                alt="Next page"
              ></img>
            </div>
          </>
        )}

        <div className="media_pagination_item media_pagination_number">
          {page + 1}
        </div>
        {mediaData.length > (page + 1) * CARDS_PER_PAGE && (
          <>
            <div
              className="media_pagination_item media_pagination_next"
              onClick={() => setPage(page + 1)}
            >
              <img src={NextIcon} alt="Next page"></img>
            </div>
            <div
              className="media_pagination_item media_pagination_last"
              onClick={() =>
                setPage(Math.floor(mediaData.length / CARDS_PER_PAGE))
              }
            >
              <img src={LastIcon} alt="Last page"></img>
            </div>
          </>
        )}
      </div>
      <div className="media_kit">
        <h2>Media Kit</h2>
        <p>
          For media inquiries or partnerships use the button below or drop us a
          line at <a href="mailto: help@keyfi.com">help@keyfi.com</a>
        </p>
        <div className="media_kit_buttoncontainer">
          <a
            className="media_kit_button media_kit_download"
            href="/docs/KeyFi-MediaKit.zip"
            download
          >
            <img src={DownloadIcon} alt="Download media kit"></img>
            <span>Download media kit</span>
          </a>
          <button
            className="media_kit_button media_kit_contact"
            onClick={() => navigate("/contact")}
          >
            Contact us
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default MediaContainer;
