import Thumbnail from "../images/mediaThumbnails/media1.jpg";
import Thumbnail2 from "../images/mediaThumbnails/media2.jpg";
import Thumbnail3 from "../images/mediaThumbnails/media3.jpg";
import Thumbnail4 from "../images/mediaThumbnails/media4.jpg";
import Thumbnail5 from "../images/mediaThumbnails/media5.jpg";
import Thumbnail6 from "../images/mediaThumbnails/media6.jpg";

export const mediaData = [
  {
    title: "KeyFi Comes to BSC-Based ApeSwap DEX",
    mediaCompany: "CryptoPotato",
    img: Thumbnail5,
    link: "https://cryptopotato.com/keyfi-comes-to-bsc-based-apeswap-dex",
  },
  {
    title: "KeyFi Announces Native Token Listing on ApeSwap DEX",
    mediaCompany: "Blockchain.News",
    img: Thumbnail3,
    link:
      "https://blockchain.news/news/keyfi-announces-native-token-listing-on-apeswap-dex",
  },
  {
    title:
      "KeyFi Partners with Binance Smart Chain-based DEX, ApeSwap, Giving out 300 KEYFI to Community",
    mediaCompany: "BTC Manager",
    img: Thumbnail6,
    link:
      "https://btcmanager.com/keyfi-partners-binance-smart-chain-dex-apeswap-300-keyfi/",
  },
  {
    title: "KeyFi to Launch Native Token on Binance Smart Chain",
    mediaCompany: "Yahoo Finance",
    img: Thumbnail,
    link:
      "https://finance.yahoo.com/news/keyfi-launch-native-token-binance-150000764.html",
  },
  {
    title: "Meet KeyFi: An AI Powered, Non-Custodial DeFi Dashboard",
    mediaCompany: "Blockonomi",
    img: Thumbnail3,
    link: "https://blockonomi.com/keyfi-guide/",
  },
  {
    title:
      "Binance Will Support the SelfKey (KEY) & KeyFi (KEYFI) Airdrop Program for SelfKey (KEY) Holders",
    mediaCompany: "Binance",
    img: Thumbnail2,
    link:
      "https://www.binance.com/en/support/announcement/a976b8105e0c48179b29c1757f9a623f",
  },
  {
    title:
      "KeyFi Teams Up with Binance Smart Chain to Reward Long-Term Holders with Airdrop",
    mediaCompany: "ZyCrypto",
    img: Thumbnail4,
    link:
      "https://zycrypto.com/keyfi-teams-up-with-binance-smart-chain-to-reward-long-term-holders-with-airdrop/",
  },
  {
    title: "Binance to Support KEYFI and KEY Airdrops",
    mediaCompany: "BTC Manager",
    img: Thumbnail6,
    link: "https://btcmanager.com/binance-support-keyfi-key-airdrops/",
  },
  {
    title: "Binance announces support for KEYFI and KEY airdrop",
    mediaCompany: "Crypto News Flash",
    img: Thumbnail5,
    link:
      "https://www.crypto-news-flash.com/binance-announces-support-for-keyfi-and-key-airdrop/",
  },
  {
    title: "KeyFi Starts KYC Verification For Staking And Liquidity Mining",
    mediaCompany: "CryptoCoin.News",
    img: Thumbnail,
    link:
      "https://cryptocoin.news/news/keyfi-starts-kyc-verification-for-staking-and-liquidity-mining-53549/",
  },
  {
    title:
      "SelfKey (KEY) gains 450% after DeFi pivot and data management upgrades",
    mediaCompany: "Cointelegraph",
    img: Thumbnail2,
    link:
      "https://cointelegraph.com/news/selfkey-key-gains-450-after-defi-pivot-and-data-management-upgrades",
  },
  {
    title: "KeyFi to Launch Native Token on Binance Smart Chain",
    mediaCompany: "AccessWire",
    img: Thumbnail4,
    link:
      "https://www.accesswire.com/637429/KeyFi-to-Launch-Native-Token-on-Binance-Smart-Chain",
  },
  {
    title:
      "KeyFi DeFi Platform Launches Token on BSC, Teases Pro Application Release",
    mediaCompany: "U.Today",
    img: Thumbnail6,
    link:
      "https://u.today/keyfi-defi-platform-launches-token-on-bsc-teases-pro-application-release",
  },
  {
    title: "KeyFi to Launch Native Token on Binance Smart Chain",
    mediaCompany: "Digital Journal",
    img: Thumbnail3,
    link: "http://www.digitaljournal.com/pr/5019987",
  },
  {
    title: "KeyFi Is Launching Its Native Token on Binance Smart Chain",
    mediaCompany: "CoinQuora",
    img: Thumbnail,
    link:
      "https://coinquora.com/keyfi-is-launching-its-native-token-on-binance-smart-chain/",
  },
  {
    title: "KeyFi Announces Native Token Launch on Binance Smart Chain",
    mediaCompany: "Coinspeaker",
    img: Thumbnail3,
    link: "https://www.coinspeaker.com/keyfi-native-token-binance-smart-chain/",
  },
  {
    title: "KeyFi Is Launching Its Native Token on Binance Smart Chain",
    mediaCompany: "Investing.com",
    img: Thumbnail5,
    link:
      "https://coinquora.com/keyfi-is-launching-its-native-token-on-binance-smart-chain/",
  },
  {
    title: "DeFi Platformu KeyFi, Token’ını Binance Smart Chain’de Çıkardı!",
    mediaCompany: "KriptoArena",
    img: Thumbnail2,
    link:
      "https://www.kriptoarena.com/defi-platformu-keyfi-tokenini-binance-smart-chainde-cikardi/",
  },
  {
    title: "KeyFi宣布在币安Smart Chain上推出原生令牌",
    mediaCompany: "0x资讯",
    img: Thumbnail4,
    link: "https://0xzx.com/2021032823431300978.html",
  },
  {
    title:
      "KeyFi Becomes Latest Defi Protocol to Launch its Native Token on BSC",
    mediaCompany: "CoinGape",
    img: Thumbnail6,
    link:
      "https://coinquora.com/keyfi-is-launching-its-native-token-on-binance-smart-chain/",
  },
  {
    title: "Keyfi: KeyFi to Launch Native Token on Binance Smart Chain",
    mediaCompany: "FinanceNachrichten",
    img: Thumbnail2,
    link:
      "https://www.finanznachrichten.de/nachrichten-2021-03/52421865-keyfi-keyfi-to-launch-native-token-on-binance-smart-chain-200.htm",
  },
  {
    title: "KeyFi Announces Launch of Native Token on Binance Smart Chain",
    mediaCompany: "Blockonomi",
    img: Thumbnail4,
    link: "https://blockonomi.com/keyfi-binance-smart-chain/",
  },
  {
    title:
      "PancakeSwap Rebounds Higher as KeyFi DeFi Token Launches on its Platform",
    mediaCompany: "Blockchain News",
    img: Thumbnail6,
    link:
      "https://blockchain.news/analysis/pancakeswap-rebounds-higher-keyfi-defi-token-launches-platform",
  },
  {
    title:
      "KeyFi, a management tool for decentralized financial protocols, has announced the launch of its token on Binance",
    mediaCompany: "Reddit",
    img: Thumbnail,
    link:
      "https://www.reddit.com/r/CryptoCurrencyTrading/comments/mfpu7c/keyfi_a_management_tool_for_decentralized/",
  },
  {
    title: "KeyFi Announces Native Token Launch on Binance Smart Chain",
    mediaCompany: "Reddit",
    img: Thumbnail3,
    link:
      "https://www.reddit.com/r/CryptoMarkets/comments/mfm76d/keyfi_announces_native_token_launch_on_binance/",
  },
];
