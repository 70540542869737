import React from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../components/theme";
import styled from "styled-components";

// Components
import Footer from "../components/Footer";
import MetaComponent from "../components/MetaComponent";
import Menu from "../components/Menu";
import HeroMedia from "../components/press/HeroMedia";
import MediaContainer from "../components/press/MediaContainer";
import MediaIcons from "../components/homePage/MediaIcons";

import { graphql } from "gatsby";

const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
  background: white;
`;

const Press = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="Press" />
      <Wrapper>
        <Menu />
        <HeroMedia />
        <MediaIcons />
        <MediaContainer />
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Press;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
