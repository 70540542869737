import React from "react";
import styled from "styled-components";
import Blockonomi from "../../images/blockonomi-logo.png";
import CoinQuora from "../../images/CoinQuoraLogoFinal.svg";
import Cointelegraph from "../../images/cointelegraph.svg";
import Investing from "../../images/investing.png";
import Yahoo from "../../images/Yahoo.png";

const Wrapper = styled.div`
  background-color: var(--brandGrey);
  .icon_container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 3.5em 5em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 375px) {
      padding: 4em 1em;
    }
    img {
      margin: 1em;
      height: 30px;
      @media (max-width: 375px) {
        height: 26px;
        margin: 1em 0.3em;
      }
    }
    .Yahoo {
      height: 36px;
    }
    .Blockonomi {
      height: 26px;
    }
  }
`;

const MediaIcons = () => {
  const media = [
    <a href="https://finance.yahoo.com/news/keyfi-launch-native-token-binance-150000764.html" target="_blank" rel="noreferrer"><img src={Yahoo} className="Yahoo" alt="Yahoo"></img></a>,
    <a href="https://www.investing.com/news/cryptocurrency-news/keyfi-is-launching-its-native-token-on-binance-smart-chain-2459398" target="_blank" rel="noreferrer"><img src={Investing} alt="Investing"></img></a>,
    <a href="https://cointelegraph.com/news/selfkey-key-gains-450-after-defi-pivot-and-data-management-upgrades" target="_blank" rel="noreferrer"><img src={Cointelegraph} alt="Cointelegraph"></img></a>,
    <a href="https://blockonomi.com/keyfi-binance-smart-chain/" target="_blank" rel="noreferrer"><img src={Blockonomi} className="Blockonomi" alt="Blockonomi"></img></a>,
    <a href="https://coinquora.com/keyfi-is-launching-its-native-token-on-binance-smart-chain/" target="_blank" rel="noreferrer"><img src={CoinQuora} alt="CoinQuora"></img></a>,
  ];

  return (
    <Wrapper>
      <div className="icon_container">{media.map((item) => item)}</div>
    </Wrapper>
  );
};

export default MediaIcons;
