import React from "react";
import leftDots from "../../images/leftDots.png";
import MediaBackground from "../../images/media_background.png";
import * as styles from "./heroMedia.module.css";

export default function HeroMedia() {
  return (
    <div
      className={styles.hero}
      style={{
        background: `url(${MediaBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div className={styles.heroLayer}></div>
      <div className={styles.leftDots}>
        <img src={leftDots} alt="Dots in the background" />
      </div>
      <div className={styles.rightDots}>
        <img src={leftDots} alt="Dots in the background" />
      </div>

      <div className={styles.heroGeneralText}>
        <h1>Press</h1>
        <h2>Latest news about KeyFi</h2>
      </div>
    </div>
  );
}
